import gql from "graphql-tag";

export const getProductos = gql`
  query {
    getProductos(query: { tipoProductoPlanilla: MATERIAL_EMPAQUE }) {
      sinv_pro_id
      sinv_pro_codigo
      sinv_pro_codigo_alterno
      sinv_pro_descripcion
      sinv_ume_siglassalida
    }
  }
`;

export const createProcesoMaterialEmpaque = gql`
  mutation createProcesoMaterialEmpaque($sinv_pro_id: Int!, $prprcId: Int!) {
    createProcesoMaterialEmpaque(
      data: { sinv_pro_id: $sinv_pro_id, prprcId: $prprcId }
    )
  }
`;

export const getProcesosMaterialEmpaque = gql`
  query getProcesosMaterialEmpaque($prprcId: Int) {
    getProcesosMaterialEmpaque(query: { prprcId: $prprcId }) {
      prpmeId
      sinv_pro_descripcion
      sinv_pro_id
      prprcId
      sadm_cor_id
      sadm_emp_id
      sadm_est_id
      sadm_eliminado
      sadm_idcrea
      sadm_fecha_creado
      sadm_fecha_modificado
    }
  }
`;

export const deleteProducto = gql`
  mutation updateProcesoMaterialEmpaque($prpmeId: ID!, $estado: Boolean) {
    updateProcesoMaterialEmpaque(
      id: $prpmeId
      data: { sadm_eliminado: $estado }
    )
  }
`;

export const createOrdenProduccionMaterialEmpaque = gql`
  mutation createOrdenProduccionMaterialEmpaque(
    $sinv_pro_id: Int!
    $proprId: Int!
    $propmCantidad: Float!
  ) {
    createOrdenProduccionMaterialEmpaque(
      data: {
        sinv_pro_id: $sinv_pro_id
        proprId: $proprId
        propmCantidad: $propmCantidad
      }
    )
  }
`;

export const getOrdenesProduccionMaterialEmpaque = gql`
  query getOrdenesProduccionMaterialEmpaque($proprId: Int) {
    getOrdenesProduccionMaterialEmpaque(query: { proprId: $proprId }) {
      propmId
      sinv_pro_id
      proprId
      sinv_pro_descripcion
      propmCantidad
      sadm_cor_id
      sadm_emp_id
      sadm_est_id
      sadm_eliminado
      sadm_idcrea
      sadm_fecha_creado
      sadm_fecha_modificado
    }
  }
`;

export const updateOrdenProduccionMaterialEmpaque = gql`
  mutation updateOrdenProduccionMaterialEmpaque(
    $propmId: ID!
    $sinv_pro_id: Int
    $propmCantidad: Float
    $proprId: Int
  ) {
    updateOrdenProduccionMaterialEmpaque(
      id: $propmId
      data: {
        sinv_pro_id: $sinv_pro_id
        propmCantidad: $propmCantidad
        proprId: $proprId
        sadm_eliminado: false
      }
    )
  }
`;

export const deleteOrdenProduccion = gql`
  mutation updateOrdenProduccionMaterialEmpaque(
    $propmId: ID!
    $estado: Boolean
  ) {
    updateOrdenProduccionMaterialEmpaque(
      id: $propmId
      data: { sadm_eliminado: $estado }
    )
  }
`;
