import gql from "graphql-tag";

export const getAccesos = gql`
  query getAccesosEstablecimientos($id: ID!) {
    getAccesosEstablecimientos(id: $id) {
      corId
      razon
      siglas
      status
      empresas {
        empId
        razon
        siglas
        establecimientos {
          estId
          razon
          siglas
          direccion
        }
      }
    }
  }
`;
