import gql from "graphql-tag";

export const getProductos = gql`
  query {
    getProductos(query: { tipoProductoPlanilla: MAQUINARIA }) {
      sinv_pro_id
      sinv_pro_codigo
      sinv_pro_codigo_alterno
      sinv_pro_descripcion
      sinv_ume_siglassalida
    }
  }
`;

export const createProcesoMaquinas = gql`
  mutation createProcesoMaquinas(
    $prpmaCostoHora: Float!
    $sinv_pro_id: Int!
    $prprcId: Int!
  ) {
    createProcesoMaquinas(
      data: {
        prpmaCostoHora: $prpmaCostoHora
        sinv_pro_id: $sinv_pro_id
        prprcId: $prprcId
      }
    )
  }
`;

export const getProcesosMaquinas = gql`
  query getProcesosMaquinas($prprcId: Int) {
    getProcesosMaquinas(query: { prprcId: $prprcId }) {
      prpmaId
      sinv_pro_descripcion
      sinv_pro_id
      prprcId
      sadm_cor_id
      sadm_emp_id
      sadm_est_id
      sadm_eliminado
      sadm_idcrea
      sadm_fecha_creado
      sadm_fecha_modificado
    }
  }
`;

export const getOrdenesProduccionMaquinas = gql`
  query getOrdenesProduccionMaquinas($proprId: Int) {
    getOrdenesProduccionMaquinas(query: { proprId: $proprId }) {
      propaId
      sinv_pro_id
      proprId
      sinv_pro_descripcion
      propaCostoHora
      propaCantidad
      sadm_cor_id
      sadm_emp_id
      sadm_est_id
      sadm_eliminado
      sadm_idcrea
      sadm_fecha_creado
      sadm_fecha_modificado
    }
  }
`;

export const createOrdenProduccionMaquinas = gql`
  mutation createOrdenProduccionMaquinas(
    $propaCostoHora: Float!
    $propaCantidad: Int!
    $sinv_pro_id: Int!
    $proprId: Int!
  ) {
    createOrdenProduccionMaquinas(
      data: {
        propaCostoHora: $propaCostoHora
        propaCantidad: $propaCantidad
        sinv_pro_id: $sinv_pro_id
        proprId: $proprId
      }
    )
  }
`;

export const updateOrdenProduccionMaquinas = gql`
  mutation updateOrdenProduccionMaquinas(
    $propaId: ID!
    $sinv_pro_id: Int
    $propaCostoHora: Float
    $propaCantidad: Int
    $proprId: Int
  ) {
    updateOrdenProduccionMaquinas(
      id: $propaId
      data: {
        sinv_pro_id: $sinv_pro_id
        propaCostoHora: $propaCostoHora
        proprId: $proprId
        sadm_eliminado: false
        propaCantidad: $propaCantidad
      }
    )
  }
`;

export const deleteOrdenProduccion = gql`
  mutation updateOrdenProduccionMaquinas($propaId: ID!, $estado: Boolean) {
    updateOrdenProduccionMaquinas(
      id: $propaId
      data: { sadm_eliminado: $estado }
    )
  }
`;

export const deleteProducto = gql`
  mutation updateProcesoMaquinas($prpmaId: ID!, $estado: Boolean) {
    updateProcesoMaquinas(id: $prpmaId, data: { sadm_eliminado: $estado })
  }
`;
