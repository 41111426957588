import gql from "graphql-tag";

export const findOrdenesProduccion = gql`
  query {
    findOrdenesProduccion {
      proprId
      proprFecha
      proprNumero
      proprCorrelativo
      proprReferencia
      sadm_ser_id
      prprcId
      sadm_cor_id
      sadm_emp_id
      sadm_est_id
      sadm_eliminado
      sadm_idcrea
      sadm_fecha_creado
      sadm_fecha_modificado
      proprStatus
    }
  }
`;

export const createOrdenProduccion = gql`
  mutation createOrdenProduccion($data: CreateOrdenProduccionInput!) {
    createOrdenProduccion(data: $data) {
      insertId
      proprNumero
      proprCorrelativo
      sadm_ser_id
    }
  }
`;

export const updateOrdenProduccion = gql`
  mutation updateOrdenProduccion(
    $proprId: ID!
    $data: UpdateOrdenProduccionInput!
  ) {
    updateOrdenProduccion(id: $proprId, data: $data)
  }
`;

export const cerrarOrdenProduccion = gql`
  mutation cerrarOrdenProduccion($data: CerrarOrdenProduccionQuery!) {
    cerrarOrdenProduccion(query: $data)
  }
`;

export const deleteOrdenProduccion = gql`
  mutation updateProceso($proprId: ID!, $estado: Boolean) {
    updateOrdenProduccion(id: $proprId, data: { sadm_eliminado: $estado })
  }
`;
