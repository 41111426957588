import {
  findEmpleados,
  getOrdenesProduccionEm,
  createOrdenProduccionEm,
  updateOrdenProduccionEm,
  deleteOrdenProduccionEm,
} from "@/api/employees";
import { apolloProvider } from "../../vue-apollo";
// const apolloClient = apolloProvider.clients.planilla;
const apolloClientDefault = apolloProvider.defaultClient;

const state = () => ({
  employees: [],
  loading: false,
  ordenes: [],
  loadingOrdenes: false,
  loadingSave: false,
});

// getters
const getters = {};

// actions
const actions = {
  findEmployees({ commit }) {
    commit("setLoading", true);
    apolloClientDefault
      .query({ query: findEmpleados })
      .then(({ data }) => {
        commit("setEmployees", data.getEmpleados?.empleados || []);
      })
      .catch((error) => {
        console.error(error);
        commit("setLoading", false);
      });
  },
  getOrdenesProduccionEm({ commit }) {
    const data = {
      proprId: +localStorage.getItem("proprId"),
    };
    commit("setLoadingOrdenes", true);
    apolloClientDefault
      .query({ query: getOrdenesProduccionEm, variables: data })
      .then(({ data }) => {
        commit("setOrdenesProduccionEm", data.getOrdenesProduccionEm);
      })
      .catch((error) => {
        console.error(error);
        commit("setLoadingOrdenes", false);
      });
  },
  createEmployee({ commit }, { data }) {
    commit("setLoadingSave", true);
    return new Promise((resolve, reject) => {
      apolloClientDefault
        .mutate({
          mutation: createOrdenProduccionEm,
          variables: { ...data },
        })
        .then((employee) => {
          commit("createEmployee", {
            ...data,
            proptId: employee.data.createOrdenProduccionEm,
          });
          resolve(true);
        })
        .catch((error) => {
          reject(error);
          commit("setLoadingSave", false);
        });
    });
  },
  deleteEmployee({ commit }, { data }) {
    commit("setLoadingSave", true);
    return new Promise((resolve, reject) => {
      apolloClientDefault
        .mutate({ mutation: deleteOrdenProduccionEm, variables: { ...data } })
        .then(() => {
          commit("deleteEmployee", data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
          commit("setLoadingSave", false);
        });
    });
  },
  updateEmployee({ commit }, { data }) {
    commit("setLoadingSave", true);
    return new Promise((resolve, reject) => {
      apolloClientDefault
        .mutate({
          mutation: updateOrdenProduccionEm,
          variables: { ...data },
        })
        .then(() => {
          commit("updateEmployee", data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
          commit("setLoadingSave", false);
        });
    });
  },
};

// mutations
const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setEmployees(state, employees) {
    state.loading = false;
    state.employees = employees;
  },
  setLoadingOrdenes(state, loading) {
    state.loadingOrdenes = loading;
  },
  setOrdenesProduccionEm(state, ordenes) {
    state.ordenes = ordenes;
    state.loadingOrdenes = false;
  },
  setLoadingSave(state, loading) {
    state.loadingSave = loading;
  },
  createEmployee(state, employee) {
    state.loadingSave = false;
    state.ordenes = [
      {
        ...employee,
        sadm_eliminado: false,
        sadm_fecha_creado: new Date().getTime(),
      },
      ...state.ordenes,
    ];
  },
  updateEmployee(state, employee) {
    employee.proptId = employee.proptId.toString();
    const index = state.ordenes.findIndex(
      (item) => item.proptId === employee.proptId
    );
    const items = [...state.ordenes];
    items[index] = {
      ...items[index],
      ...employee,
      sadm_fecha_modificado: new Date().getTime(),
    };
    state.loadingSave = false;
    state.ordenes = items;
  },
  deleteEmployee(state, employee) {
    const index = state.ordenes.findIndex(
      (item) => item.proptId === employee.proptId
    );
    const items = [...state.ordenes];
    items[index] = {
      ...items[index],
      sadm_fecha_modificado: new Date().getTime(),
      sadm_eliminado: employee.estado,
    };
    state.loadingSave = false;
    state.ordenes = items;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
