import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
import Layout from "@/layouts/Layout";
import { Plugin } from "vue-fragment";
import i18n from "./i18n";
import { apolloProvider } from "./vue-apollo";

// i18n.locale = "en";
Vue.config.productionTip = false;
Vue.component("Layout", Layout);
Vue.use(Plugin);
Vue.use(VueToast);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  apolloProvider: apolloProvider,
  render: (h) => h(App),
}).$mount("#app");
