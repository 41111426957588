<template>
  <v-card class="mx-auto rounded soft_shadow" color="secondary">
    <v-row no-gutters class="">
      <v-col cols="12" md="12" class="">
        <v-img height="350" :src="'/img/fondo-login.jpg'"> </v-img>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "DisagroInstallApp",
  props: {
    mini: Boolean,
  },
  data: function () {
    return {
      dialog: false,
      loadingSync: false,
    };
  },
  methods: {
    handleSync() {
      this.loadingSync = true;
      setTimeout(() => {
        this.loadingSync = false;
        this.dialog = false;
      }, 3000);
    },
  },
};
</script>

<style scoped>
.middle-rounded {
  border-radius: 4px 0px 0px 4px !important;
}
</style>
<style scoped src="@/assets/css/globals.css"></style>
