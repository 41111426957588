import getSymbolFromCurrency from "currency-symbol-map";
import Excel from "exceljs";
import vuetify from "../../plugins/vuetify";
const vuetifyLightTheme = { ...vuetify.userPreset.theme.themes.light };

export async function catalogReport(params) {
  const workbook = new Excel.Workbook();
  const worksheet = workbook.addWorksheet(params.name);
  const data = params.data;
  const INITROW = params.dates ? 5 : 4;
  let moneda = localStorage.getItem("currency");
  let currencyFormat = "* 0.00";
  if (moneda) {
    let symbol = getSymbolFromCurrency(moneda);
    // por si no encuentra el simbolo
    if (symbol) {
      currencyFormat = `${symbol}* 0.00`;
    }
  }
  //const numFmt = params.moneda === "GTQ" ? "Q0.00" : "$0.00";
  const numFmt = currencyFormat;
  worksheet.columns = params.columns;
  setTitle();
  setStyleHeadings(worksheet.getRow(INITROW));
  body(data);
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const url = window.URL.createObjectURL(blob);
  const anchor = document.createElement("a");
  anchor.href = url;
  anchor.download = params.name + ".xlsx";
  anchor.click();
  window.URL.revokeObjectURL(url);

  function setTitle() {
    worksheet.duplicateRow(1, INITROW - 1, true);
    for (let i = 1; i < INITROW; i++) {
      let tempRow = worksheet.getRow(i);
      tempRow.values = [];
      tempRow.font = { bold: true };
    }
    if (localStorage.getItem("establishmentName") !== null) {
      worksheet.getRow(1).values = [
        `${localStorage.getItem("establishmentName").toUpperCase()}`,
      ];
    }

    worksheet.getRow(2).values = [`REPORTE DE ${params.name.toUpperCase()}`];
    if (params.dates) {
      worksheet.getRow(3).values = [
        `RANGO DE FECHAS: DEL ${params.dates.inicio} AL ${params.dates.fin}`,
      ];
    }
  }
  function setStyleHeadings(row) {
    let fgColor = vuetifyLightTheme[`${params.color}`]
      ? vuetifyLightTheme[`${params.color}`]
      : "#004d00";
    row.eachCell(function (cell) {
      // altura
      row.height = 24;
      // relleno
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: fgColor.replace("#", "") },
      };
      cell.alignment = { vertical: "middle" };
      // Texto
      cell.font = {
        color: { argb: "FFFFFF" },
        bold: true,
      };
      cell.border = {
        right: { style: "thin", color: { argb: "FFFFFF" } },
      };
    });
  }
  function body(data) {
    data.forEach((item, index) => {
      let itemSave = {};
      let numFmtCells = [];
      for (let key in item) {
        const { value, type, column } = item[key];
        itemSave[key] = value;
        if (type === "moneda") {
          numFmtCells.push(`${column}${index + INITROW + 1}`);
        }
      }
      worksheet.insertRow(index + INITROW + 1, {
        ...itemSave,
      });
      numFmtCells.forEach((numberCell) => {
        worksheet.getCell(`${numberCell}`).numFmt = numFmt;
      });
    });
  }
}
