import gql from "graphql-tag";

export const getProductos = gql`
  query {
    getProductos(query: { tipoProductoPlanilla: PRODUCTO_SALIDA }) {
      sinv_pro_id
      sinv_pro_codigo
      sinv_pro_codigo_alterno
      sinv_pro_descripcion
      sinv_ume_siglassalida
    }
  }
`;

export const createProcesoProductosSalida = gql`
  mutation createProcesoProductosSalida($sinv_pro_id: Int!, $prprcId: Int!) {
    createProcesoProductosSalida(
      data: { sinv_pro_id: $sinv_pro_id, prprcId: $prprcId }
    )
  }
`;

export const createOrdenProduccionSalida = gql`
  mutation createOrdenProduccionSalida(
    $sinv_pro_id: Int!
    $proprId: Int!
    $propsPeso: Float!
  ) {
    createOrdenProduccionSalida(
      data: {
        sinv_pro_id: $sinv_pro_id
        proprId: $proprId
        propsPeso: $propsPeso
      }
    )
  }
`;

export const getProcesosProductosSalida = gql`
  query getProcesosProductosSalida($prprcId: Int) {
    getProcesosProductosSalida(query: { prprcId: $prprcId }) {
      prppsId
      sinv_pro_descripcion
      sinv_pro_id
      prprcId
      sadm_cor_id
      sadm_emp_id
      sadm_est_id
      sadm_eliminado
      sadm_idcrea
      sadm_fecha_creado
      sadm_fecha_modificado
    }
  }
`;

export const getOrdenesProduccionSalida = gql`
  query getOrdenesProduccionSalida($proprId: Int) {
    getOrdenesProduccionSalida(query: { proprId: $proprId }) {
      propsId
      sinv_pro_id
      proprId
      sinv_pro_descripcion
      propsPeso
      sadm_cor_id
      sadm_emp_id
      sadm_est_id
      sadm_eliminado
      sadm_idcrea
      sadm_fecha_creado
      sadm_fecha_modificado
    }
  }
`;

export const deleteProducto = gql`
  mutation updateProcesoProductosSalida($prppsId: ID!, $estado: Boolean) {
    updateProcesoProductosSalida(
      id: $prppsId
      data: { sadm_eliminado: $estado }
    )
  }
`;

export const updateOrdenProduccionSalida = gql`
  mutation updateOrdenProduccionSalida(
    $propsId: ID!
    $sinv_pro_id: Int
    $propsPeso: Float
    $proprId: Int
  ) {
    updateOrdenProduccionSalida(
      id: $propsId
      data: {
        sinv_pro_id: $sinv_pro_id
        propsPeso: $propsPeso
        proprId: $proprId
        sadm_eliminado: false
      }
    )
  }
`;

export const deleteOrdenProduccion = gql`
  mutation updateOrdenProduccionSalida($propsId: ID!, $estado: Boolean) {
    updateOrdenProduccionSalida(id: $propsId, data: { sadm_eliminado: $estado })
  }
`;
