import {
  getProductos,
  createProcesoMaterialEmpaque,
  getProcesosMaterialEmpaque,
  getOrdenesProduccionMaterialEmpaque,
  deleteProducto,
  createOrdenProduccionMaterialEmpaque,
  updateOrdenProduccionMaterialEmpaque,
  deleteOrdenProduccion,
} from "../../api/materialEmpaque";
import { apolloProvider } from "../../vue-apollo";
const apolloClient = apolloProvider.defaultClient;

const state = () => ({
  materialesEmpaque: [],
  materialesEmpaqueProc: [],
  materialesEmpaqueProduccion: [],
  materialEmpaque: null,
  loadingSave: false,
  loading: false,
});

// getters
const getters = {};

// actions
const actions = {
  findProductosMaterialEmpaque({ commit }) {
    commit("setLoading", true);
    apolloClient
      .query({ query: getProductos })
      .then(({ data }) => {
        commit(
          "setProductoMaterialEmpaque",
          data.getProductos.slice().reverse()
        );
      })
      .catch((error) => {
        console.error(error);
        commit("setLoading", false);
      });
  },
  getProcesosMaterialEmpaque({ commit }) {
    const data = {
      prprcId: +localStorage.getItem("prprcId"),
    };
    commit("setLoading", true);
    apolloClient
      .query({ query: getProcesosMaterialEmpaque, variables: data })
      .then(({ data }) => {
        commit(
          "setProcProductoMaterialEmpaque",
          data.getProcesosMaterialEmpaque.slice().reverse()
        );
      })
      .catch((error) => {
        console.error(error);
        commit("setLoading", false);
      });
  },
  getOrdenesProduccionMaterialEmpaque({ commit }) {
    const data = {
      proprId: +localStorage.getItem("proprId"),
    };
    commit("setLoading", true);
    apolloClient
      .query({ query: getOrdenesProduccionMaterialEmpaque, variables: data })
      .then(({ data }) => {
        commit(
          "setProcProductoMaterialEmpaqueProduccion",
          data.getOrdenesProduccionMaterialEmpaque.slice().reverse()
        );
      })
      .catch((error) => {
        console.error(error);
        commit("setLoading", false);
      });
  },
  createProcesoMaterialEmpaque({ commit }, { data }) {
    commit("setLoadingSave", true);
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: createProcesoMaterialEmpaque,
          variables: { ...data },
        })
        .then((productoEntrada) => {
          commit("createProcesoMaterialEmpaque", {
            ...data,
            propeId: productoEntrada.data.createProcesoMaterialEmpaque,
          });
          resolve(true);
        })
        .catch((error) => {
          reject(error);
          commit("setLoadingSave", false);
        });
    });
  },
  createOrdenProduccionMaterialEmpaque({ commit }, { data }) {
    commit("setLoadingSave", true);
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: createOrdenProduccionMaterialEmpaque,
          variables: { ...data },
        })
        .then((productoEntrada) => {
          commit("createOrdenProduccionMaterialEmpaque", {
            ...data,
            propmId: productoEntrada.data.createOrdenProduccionMaterialEmpaque,
          });
          resolve(true);
        })
        .catch((error) => {
          reject(error);
          commit("setLoadingSave", false);
        });
    });
  },
  deleteMaterialEmpaque({ commit }, { data }) {
    commit("setLoadingSave", true);
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({ mutation: deleteProducto, variables: { ...data } })
        .then(() => {
          commit("deleteMaterialEmpaque", data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
          commit("setLoadingSave", false);
        });
    });
  },
  updateOrdenProduccionMaterialEmpaque({ commit }, { data }) {
    commit("setLoadingSave", true);
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: updateOrdenProduccionMaterialEmpaque,
          variables: { ...data },
        })
        .then(() => {
          commit("updateOrdenProduccionMaterialEmpaque", data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
          commit("setLoadingSave", false);
        });
    });
  },
  deleteOrdenProduccion({ commit }, { data }) {
    commit("setLoadingSave", true);
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({ mutation: deleteOrdenProduccion, variables: { ...data } })
        .then(() => {
          commit("deleteOrdenProduccion", data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
          commit("setLoadingSave", false);
        });
    });
  },
};

// mutations
const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setLoadingSave(state, loading) {
    state.loadingSave = loading;
  },
  setProductoMaterialEmpaque(state, materialesEmpaque) {
    state.loading = false;
    state.materialesEmpaque = materialesEmpaque;
  },
  setProcProductoMaterialEmpaque(state, materialesEmpaque) {
    state.loading = false;
    state.materialesEmpaqueProc = materialesEmpaque;
  },
  setProcProductoMaterialEmpaqueProduccion(state, materialesEmpaque) {
    state.loading = false;
    state.materialesEmpaqueProduccion = materialesEmpaque;
  },
  createProcesoMaterialEmpaque(state, materialEmpaque) {
    state.loadingSave = false;
    state.materialesEmpaqueProc = [
      {
        ...materialEmpaque,
        sadm_eliminado: false,
        sinv_pro_descripcion: localStorage.getItem("sinv_pro_descripcion_temp"),
        sadm_fecha_creado: new Date().getTime(),
      },
      ...state.materialesEmpaqueProc,
    ];
  },
  createOrdenProduccionMaterialEmpaque(state, materialEmpaque) {
    state.loadingSave = false;
    state.materialesEmpaqueProduccion = [
      {
        ...materialEmpaque,
        sadm_eliminado: false,
        sinv_pro_descripcion: localStorage.getItem("sinv_pro_descripcion_temp"),
        sadm_fecha_creado: new Date().getTime(),
      },
      ...state.materialesEmpaqueProduccion,
    ];
  },
  deleteMaterialEmpaque(state, materialEmpaque) {
    const index = state.materialesEmpaque.findIndex(
      (item) => item.prpmeId === materialEmpaque.prpmeId
    );
    const items = [...state.materialesEmpaque];
    items[index] = {
      ...items[index],
      sadm_fecha_modificado: new Date().getTime(),
      sadm_eliminado: materialEmpaque.estado,
    };
    state.loadingSave = false;
    state.materialesEmpaque = items;
  },
  updateOrdenProduccionMaterialEmpaque(state, materialEmpaque) {
    const index = state.materialesEmpaqueProduccion.findIndex(
      (item) => item.propmId == materialEmpaque.propmId
    );
    const items = [...state.materialesEmpaqueProduccion];
    items[index] = {
      ...items[index],
      ...materialEmpaque,
      sadm_fecha_modificado: new Date().getTime(),
    };
    state.loadingSave = false;
    state.materialesEmpaqueProduccion = items;
  },
  deleteOrdenProduccion(state, materialEmpaque) {
    const index = state.materialesEmpaqueProduccion.findIndex(
      (item) => item.propmId == materialEmpaque.propmId
    );
    const items = [...state.materialesEmpaqueProduccion];
    items[index] = {
      ...items[index],
      sadm_fecha_modificado: new Date().getTime(),
      sadm_eliminado: materialEmpaque.estado,
    };
    state.loadingSave = false;
    state.materialesEmpaqueProduccion = items;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
