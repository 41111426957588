import Vue from "vue";
import Vuex from "vuex";
import config from "./modules/config.js";
import alerts from "./modules/alerts.js";
import tprocesos from "./modules/tprocesos";
import productsMP from "./modules/productsMP";
import productsEntrada from "./modules/productsEntrada";
import materialEmpaque from "./modules/materialEmpaque";
import maquinaria from "./modules/maquinaria";
import productosSalida from "./modules/productosSalida";
import subproductosSalida from "./modules/subproductosSalida";
import ordenesProduccion from "./modules/ordenesProduccion";
import employees from "./modules/employee";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    config,
    alerts,
    tprocesos,
    productsMP,
    productsEntrada,
    materialEmpaque,
    maquinaria,
    productosSalida,
    subproductosSalida,
    ordenesProduccion,
    employees,
  },
});
