import {
  getProceso,
  findProcesos,
  createProceso,
  updateProceso,
  deleteProceso,
} from "../../api/tprocesos";
import { apolloProvider } from "../../vue-apollo";
const apolloClient = apolloProvider.defaultClient;

const state = () => ({
  procesos: [],
  proceso: null,
  loadingSave: false,
  loading: false,
});

// getters
const getters = {};

// actions
const actions = {
  getProceso({ commit }, { id }) {
    commit("setLoading", true);
    apolloClient
      .query({ query: getProceso, variables: { id } })
      .then(({ data }) => {
        commit("setProceso", data.getProceso);
      })
      .catch((error) => {
        console.error(error);
        commit("setLoading", false);
      });
  },
  findProcesos({ commit, state }, force = false) {
    commit("setLoading", true);
    if (state.procesos.length > 0 && !force) {
      commit("setLoading", false);
      return;
    }
    apolloClient
      .query({ query: findProcesos })
      .then(({ data }) => {
        commit("setProcesos", data.findProcesos.slice().reverse());
      })
      .catch((error) => {
        console.error(error);
        commit("setLoading", false);
      });
  },
  createProceso({ commit }, { data }) {
    commit("setLoadingSave", true);
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({ mutation: createProceso, variables: { data } })
        .then((proceso) => {
          commit("createProceso", {
            ...data,
            prprcId: proceso.data.createProceso,
          });
          resolve(true);
        })
        .catch((error) => {
          reject(error);
          commit("setLoadingSave", false);
        });
    });
  },
  updateProceso({ commit }, { data, prprcId }) {
    commit("setLoadingSave", true);
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({ mutation: updateProceso, variables: { data, prprcId } })
        .then(() => {
          commit("updateProceso", { ...data, prprcId });
          resolve(true);
        })
        .catch((error) => {
          reject(error);
          commit("setLoadingSave", false);
        });
    });
  },
  deleteProceso({ commit }, { data }) {
    commit("setLoadingSave", true);
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({ mutation: deleteProceso, variables: { ...data } })
        .then(() => {
          commit("deleteProceso", data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
          commit("setLoadingSave", false);
        });
    });
  },
};

// mutations
const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setLoadingSave(state, loading) {
    state.loadingSave = loading;
  },
  setProcesos(state, procesos) {
    state.loading = false;
    state.procesos = procesos;
  },
  setProceso(state, proceso) {
    state.loading = false;
    state.proceso = proceso;
  },
  createProceso(state, proceso) {
    state.loadingSave = false;
    state.procesos = [
      {
        ...proceso,
        sadm_eliminado: false,
        sadm_fecha_creado: new Date().getTime(),
      },
      ...state.procesos,
    ];
  },
  updateProceso(state, proceso) {
    const index = state.procesos.findIndex(
      (item) => item.prprcId === proceso.prprcId
    );
    const items = [...state.procesos];
    items[index] = {
      ...items[index],
      ...proceso,
      sadm_fecha_modificado: new Date().getTime(),
    };
    state.loadingSave = false;
    state.procesos = items;
  },
  deleteProceso(state, proceso) {
    const index = state.procesos.findIndex(
      (item) => item.prprcId === proceso.prprcId
    );
    const items = [...state.procesos];
    items[index] = {
      ...items[index],
      sadm_fecha_modificado: new Date().getTime(),
      sadm_eliminado: proceso.estado,
    };
    state.loadingSave = false;
    state.procesos = items;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
