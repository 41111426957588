import {
  getProductos,
  getProcesosProductosEntrada,
  createProcesoProductosEntrada,
  deleteProducto,
  createOrdenProduccionEntrada,
  getOrdenesProduccionEntrada,
  updateOrdenProduccionEntrada,
  deleteOrdenProduccion,
} from "../../api/productsEntrada";
import { apolloProvider } from "../../vue-apollo";
const apolloClient = apolloProvider.defaultClient;

const state = () => ({
  productosEntrada: [],
  productosEntradaPro: [],
  productosEntradaProduccion: [],
  productoEntrada: null,
  loadingSave: false,
  loading: false,
});

// getters
const getters = {};

// actions
const actions = {
  findProductosEntrada({ commit }) {
    commit("setLoading", true);
    apolloClient
      .query({ query: getProductos })
      .then(({ data }) => {
        commit("setProductoEntrada", data.getProductos.slice().reverse());
      })
      .catch((error) => {
        console.error(error);
        commit("setLoading", false);
      });
  },
  getProcesosProductosEntrada({ commit }) {
    const data = {
      prprcId: +localStorage.getItem("prprcId"),
    };
    commit("setLoading", true);
    apolloClient
      .query({ query: getProcesosProductosEntrada, variables: data })
      .then(({ data }) => {
        commit(
          "setProductoEntradaPro",
          data.getProcesosProductosEntrada.slice().reverse()
        );
      })
      .catch((error) => {
        console.error(error);
        commit("setLoading", false);
      });
  },
  getOrdenesProduccionEntrada({ commit }) {
    const data = {
      proprId: +localStorage.getItem("proprId"),
    };
    commit("setLoading", true);
    apolloClient
      .query({ query: getOrdenesProduccionEntrada, variables: data })
      .then(({ data }) => {
        commit(
          "setProductoEntradaProduccion",
          data.getOrdenesProduccionEntrada.slice().reverse()
        );
      })
      .catch((error) => {
        console.error(error);
        commit("setLoading", false);
      });
  },
  createProcesoProductosEntrada({ commit }, { data }) {
    commit("setLoadingSave", true);
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: createProcesoProductosEntrada,
          variables: { ...data },
        })
        .then((productoEntrada) => {
          commit("createProcesoProductosEntrada", {
            ...data,
            propeId: productoEntrada.data.createProcesoProductosEntrada,
          });
          resolve(true);
        })
        .catch((error) => {
          reject(error);
          commit("setLoadingSave", false);
        });
    });
  },
  createOrdenProduccionEntrada({ commit }, { data }) {
    commit("setLoadingSave", true);
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: createOrdenProduccionEntrada,
          variables: { ...data },
        })
        .then((productoEntrada) => {
          commit("createOrdenProduccionEntrada", {
            ...data,
            propeId: productoEntrada.data.createOrdenProduccionEntrada,
          });
          resolve(true);
        })
        .catch((error) => {
          reject(error);
          commit("setLoadingSave", false);
        });
    });
  },
  updateOrdenProduccionEntrada({ commit }, { data }) {
    commit("setLoadingSave", true);
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: updateOrdenProduccionEntrada,
          variables: { ...data },
        })
        .then(() => {
          commit("updateOrdenProduccionEntrada", data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
          commit("setLoadingSave", false);
        });
    });
  },
  deleteProductoEntrada({ commit }, { data }) {
    commit("setLoadingSave", true);
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({ mutation: deleteProducto, variables: { ...data } })
        .then(() => {
          commit("deleteProductoEntrada", data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
          commit("setLoadingSave", false);
        });
    });
  },
  deleteOrdenProduccion({ commit }, { data }) {
    commit("setLoadingSave", true);
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({ mutation: deleteOrdenProduccion, variables: { ...data } })
        .then(() => {
          commit("deleteOrdenProduccion", data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
          commit("setLoadingSave", false);
        });
    });
  },
};

// mutations
const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setLoadingSave(state, loading) {
    state.loadingSave = loading;
  },
  setProductoEntrada(state, productosEntrada) {
    state.loading = false;
    state.productosEntrada = productosEntrada;
  },
  setProductoEntradaPro(state, productosEntrada) {
    state.loading = false;
    state.productosEntradaPro = productosEntrada;
  },
  setProductoEntradaProduccion(state, productosEntrada) {
    state.loading = false;
    state.productosEntradaProduccion = productosEntrada;
  },
  createProcesoProductosEntrada(state, productoEntrada) {
    state.loadingSave = false;
    state.productosEntradaPro = [
      {
        ...productoEntrada,
        sinv_pro_descripcion: localStorage.getItem("sinv_pro_descripcion_temp"),
        sadm_eliminado: false,
        sadm_fecha_creado: new Date().getTime(),
      },
      ...state.productosEntradaPro,
    ];
  },
  createOrdenProduccionEntrada(state, productoEntrada) {
    state.loadingSave = false;
    state.productosEntradaProduccion = [
      {
        ...productoEntrada,
        sinv_pro_descripcion: localStorage.getItem("sinv_pro_descripcion_temp"),
        sadm_eliminado: false,
        sadm_fecha_creado: new Date().getTime(),
      },
      ...state.productosEntradaProduccion,
    ];
  },
  updateOrdenProduccionEntrada(state, productoEntrada) {
    const index = state.productosEntradaProduccion.findIndex(
      (item) => item.propeId == productoEntrada.propeId
    );
    const items = [...state.productosEntradaProduccion];
    items[index] = {
      ...items[index],
      ...productoEntrada,
      sadm_fecha_modificado: new Date().getTime(),
    };
    state.loadingSave = false;
    state.productosEntradaProduccion = items;
  },
  deleteProductoEntrada(state, productoEntrada) {
    const index = state.productosEntrada.findIndex(
      (item) => item.prprcId === productoEntrada.prprcId
    );
    const items = [...state.productosEntrada];
    items[index] = {
      ...items[index],
      sadm_fecha_modificado: new Date().getTime(),
      sadm_eliminado: productoEntrada.estado,
    };
    state.loadingSave = false;
    state.productosEntrada = items;
  },
  deleteOrdenProduccion(state, productoEntrada) {
    const index = state.productosEntradaProduccion.findIndex(
      (item) => item.propeId == productoEntrada.propeId
    );
    const items = [...state.productosEntradaProduccion];
    items[index] = {
      ...items[index],
      sadm_fecha_modificado: new Date().getTime(),
      sadm_eliminado: productoEntrada.estado,
    };
    state.loadingSave = false;
    state.productosEntradaProduccion = items;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
