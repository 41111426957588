import {
  getProductos,
  createProcesoInsumos,
  getProcesosInsumos,
  deleteProducto,
  createOrdenProduccionInsumos,
  getOrdenesProduccionInsumos,
  updateOrdenProduccionInsumos,
  deleteOrdenProduccion,
} from "../../api/productsMP";
import { apolloProvider } from "../../vue-apollo";
const apolloClient = apolloProvider.defaultClient;

const state = () => ({
  productosMP: [],
  productosMPPro: [],
  productosMPProduccion: [],
  productoMP: null,
  loadingSave: false,
  loading: false,
});

// getters
const getters = {};

// actions
const actions = {
  findProductosPM({ commit }) {
    commit("setLoading", true);
    apolloClient
      .query({ query: getProductos })
      .then(({ data }) => {
        commit("setProductoMP", data.getProductos.slice().reverse());
      })
      .catch((error) => {
        console.error(error);
        commit("setLoading", false);
      });
  },
  getProcesosInsumos({ commit }) {
    const data = {
      prprcId: +localStorage.getItem("prprcId"),
    };
    commit("setLoading", true);
    apolloClient
      .query({ query: getProcesosInsumos, variables: data })
      .then(({ data }) => {
        commit("setProductoMPPro", data.getProcesosInsumos.slice().reverse());
      })
      .catch((error) => {
        console.error(error);
        commit("setLoading", false);
      });
  },
  getOrdenesProduccionInsumos({ commit }) {
    const data = {
      proprId: +localStorage.getItem("proprId"),
    };
    commit("setLoading", true);
    apolloClient
      .query({ query: getOrdenesProduccionInsumos, variables: data })
      .then(({ data }) => {
        commit(
          "setProductoMPProduccion",
          data.getOrdenesProduccionInsumos.slice().reverse()
        );
      })
      .catch((error) => {
        console.error(error);
        commit("setLoading", false);
      });
  },
  createProcesoInsumos({ commit }, { data }) {
    commit("setLoadingSave", true);
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: createProcesoInsumos,
          variables: { ...data },
        })
        .then((productoMP) => {
          commit("createProcesoInsumos", {
            ...data,
            propeId: productoMP.data.createProcesoInsumos,
          });
          resolve(true);
        })
        .catch((error) => {
          reject(error);
          commit("setLoadingSave", false);
        });
    });
  },
  updateOrdenProduccionInsumos({ commit }, { data }) {
    commit("setLoadingSave", true);
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: updateOrdenProduccionInsumos,
          variables: { ...data },
        })
        .then(() => {
          commit("updateOrdenProduccionInsumos", data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
          commit("setLoadingSave", false);
        });
    });
  },
  createOrdenProduccionInsumos({ commit }, { data }) {
    commit("setLoadingSave", true);
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: createOrdenProduccionInsumos,
          variables: { ...data },
        })
        .then((productoMP) => {
          commit("createOrdenProduccionInsumos", {
            ...data,
            propiId: productoMP.data.createOrdenProduccionInsumos,
          });
          resolve(true);
        })
        .catch((error) => {
          reject(error);
          commit("setLoadingSave", false);
        });
    });
  },
  deleteInsumo({ commit }, { data }) {
    commit("setLoadingSave", true);
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({ mutation: deleteProducto, variables: { ...data } })
        .then(() => {
          commit("deleteInsumo", data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
          commit("setLoadingSave", false);
        });
    });
  },
  deleteOrdenProduccion({ commit }, { data }) {
    commit("setLoadingSave", true);
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({ mutation: deleteOrdenProduccion, variables: { ...data } })
        .then(() => {
          commit("deleteOrdenProduccion", data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
          commit("setLoadingSave", false);
        });
    });
  },
};

// mutations
const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setLoadingSave(state, loading) {
    state.loadingSave = loading;
  },
  setProductoMP(state, productosMP) {
    state.loading = false;
    state.productosMP = productosMP;
  },
  setProductoMPPro(state, productosMP) {
    state.loading = false;
    state.productosMPPro = productosMP;
  },
  setProductoMPProduccion(state, productosMP) {
    state.loading = false;
    state.productosMPProduccion = productosMP;
  },
  createProcesoInsumos(state, productoMP) {
    state.loadingSave = false;
    state.productosMPPro = [
      {
        ...productoMP,
        sinv_pro_descripcion: localStorage.getItem("sinv_pro_descripcion_temp"),
        sadm_eliminado: false,
        sadm_fecha_creado: new Date().getTime(),
      },
      ...state.productosMPPro,
    ];
  },
  createOrdenProduccionInsumos(state, productoMP) {
    state.loadingSave = false;
    state.productosMPProduccion = [
      {
        ...productoMP,
        sinv_pro_descripcion: localStorage.getItem("sinv_pro_descripcion_temp"),
        sadm_eliminado: false,
        sadm_fecha_creado: new Date().getTime(),
      },
      ...state.productosMPProduccion,
    ];
  },
  updateOrdenProduccionInsumos(state, productoMP) {
    const index = state.productosMPProduccion.findIndex(
      (item) => item.propiId == productoMP.propiId
    );
    const items = [...state.productosMPProduccion];
    items[index] = {
      ...items[index],
      ...productoMP,
      sadm_fecha_modificado: new Date().getTime(),
    };
    state.loadingSave = false;
    state.productosMPProduccion = items;
  },
  deleteInsumo(state, productoMP) {
    const index = state.productosMP.findIndex(
      (item) => item.prpinId === productoMP.prpinId
    );
    const items = [...state.productosMP];
    items[index] = {
      ...items[index],
      sadm_fecha_modificado: new Date().getTime(),
      sadm_eliminado: productoMP.estado,
    };
    state.loadingSave = false;
    state.productosMP = items;
  },
  deleteOrdenProduccion(state, productoMP) {
    const index = state.productosMPProduccion.findIndex(
      (item) => item.propiId == productoMP.propiId
    );
    const items = [...state.productosMPProduccion];
    items[index] = {
      ...items[index],
      sadm_fecha_modificado: new Date().getTime(),
      sadm_eliminado: productoMP.estado,
    };
    state.loadingSave = false;
    state.productosMPProduccion = items;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
