import gql from "graphql-tag";

export const findEmpleados = gql`
  query {
    getEmpleados {
      empleados {
        plempId
        plempCodigo
        plempNombres
        plempApellidos
        sadm_eliminado
      }
      count
    }
  }
`;

export const getOrdenesProduccionEm = gql`
  query getOrdenesProduccionEm($proprId: Int) {
    getOrdenesProduccionEm(query: { proprId: $proprId }) {
      proptId
      proprId
      plempId
      sadm_eliminado
      sadm_idcrea
    }
  }
`;

export const createOrdenProduccionEm = gql`
  mutation createOrdenProduccionEm($proprId: Int!, $plempId: Int!) {
    createOrdenProduccionEm(data: { plempId: $plempId, proprId: $proprId })
  }
`;

export const updateOrdenProduccionEm = gql`
  mutation updateOrdenProduccionEm(
    $proptId: ID!
    $plempId: Int
    $proprId: Int
  ) {
    updateOrdenProduccionEm(
      id: $proptId
      data: { plempId: $plempId, proprId: $proprId, sadm_eliminado: false }
    )
  }
`;

export const deleteOrdenProduccionEm = gql`
  mutation updateOrdenProduccionEm($proptId: ID!, $estado: Boolean) {
    updateOrdenProduccionEm(id: $proptId, data: { sadm_eliminado: $estado })
  }
`;
