import gql from "graphql-tag";

export const getProductos = gql`
  query {
    getProductos(query: { tipoProductoPlanilla: PRODUCTO_ENTRADA }) {
      sinv_pro_id
      sinv_pro_codigo
      sinv_pro_codigo_alterno
      sinv_pro_descripcion
      sinv_ume_siglasentrada
    }
  }
`;

export const getProcesosProductosEntrada = gql`
  query getProcesosProductosEntrada($prprcId: Int) {
    getProcesosProductosEntrada(query: { prprcId: $prprcId }) {
      prppeId
      sinv_pro_descripcion
      sinv_pro_id
      prprcId
      sadm_cor_id
      sadm_emp_id
      sadm_est_id
      sadm_eliminado
      sadm_idcrea
      sadm_fecha_creado
      sadm_fecha_modificado
    }
  }
`;

export const getOrdenProduccionEntrada = gql`
  query getOrdenProduccionEntrada($prppeId: ID!) {
    getOrdenProduccionEntrada(id: $prppeId) {
      prppeId
      sinv_pro_descripcion
      sinv_pro_id
      prprcId
      sadm_cor_id
      sadm_emp_id
      sadm_est_id
      sadm_eliminado
      sadm_idcrea
      sadm_fecha_creado
      sadm_fecha_modificado
    }
  }
`;

export const getOrdenesProduccionEntrada = gql`
  query getOrdenesProduccionEntrada($proprId: Int) {
    getOrdenesProduccionEntrada(query: { proprId: $proprId }) {
      propeId
      proprId
      sinv_pro_id
      proprId
      sinv_pro_descripcion
      propePesoFinca
      propePesoReal
      sadm_cor_id
      sadm_emp_id
      sadm_est_id
      sadm_eliminado
      sadm_idcrea
      sadm_fecha_creado
      sadm_fecha_modificado
    }
  }
`;

export const createProcesoProductosEntrada = gql`
  mutation createProcesoProductosEntrada($sinv_pro_id: Int!, $prprcId: Int!) {
    createProcesoProductosEntrada(
      data: { sinv_pro_id: $sinv_pro_id, prprcId: $prprcId }
    )
  }
`;

export const createOrdenProduccionEntrada = gql`
  mutation createOrdenProduccionEntrada(
    $sinv_pro_id: Int!
    $proprId: Int!
    $propePesoFinca: Float!
    $propePesoReal: Float!
  ) {
    createOrdenProduccionEntrada(
      data: {
        sinv_pro_id: $sinv_pro_id
        proprId: $proprId
        propePesoFinca: $propePesoFinca
        propePesoReal: $propePesoReal
      }
    )
  }
`;

export const updateOrdenProduccionEntrada = gql`
  mutation updateOrdenProduccionEntrada(
    $propeId: ID!
    $sinv_pro_id: Int
    $propePesoFinca: Float
    $propePesoReal: Float
    $proprId: Int
  ) {
    updateOrdenProduccionEntrada(
      id: $propeId
      data: {
        sinv_pro_id: $sinv_pro_id
        propePesoFinca: $propePesoFinca
        propePesoReal: $propePesoReal
        proprId: $proprId
        sadm_eliminado: false
      }
    )
  }
`;

export const deleteOrdenProduccion = gql`
  mutation updateOrdenProduccionEntrada($propeId: ID!, $estado: Boolean) {
    updateOrdenProduccionEntrada(
      id: $propeId
      data: { sadm_eliminado: $estado }
    )
  }
`;

export const deleteProducto = gql`
  mutation updateProcesoProductosEntrada($prppeId: ID!, $estado: Boolean) {
    updateProcesoProductosEntrada(
      id: $prppeId
      data: { sadm_eliminado: $estado }
    )
  }
`;
