/* eslint-disable */
import { config, CognitoIdentityCredentials, S3 } from "aws-sdk";
import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserAttribute,
} from "amazon-cognito-identity-js";
import { identityConfig } from "../config";

config.region = identityConfig.region;
config.credentials = new CognitoIdentityCredentials({
  IdentityPoolId: identityConfig.IdentityPoolId,
});

const userPool = new CognitoUserPool({
  UserPoolId: identityConfig.UserPoolId,
  ClientId: identityConfig.ClientId,
});

function SignUp({ password, nit, email, phoneNumber }) {
  var dataEmail = {
    Name: "email",
    Value: email,
  };
  var dataNit = {
    Name: "custom:nit",
    Value: nit,
  };

  const dataNumber = {
    Name: "phone_number",
    Value: phoneNumber,
  };

  const attributeList = [
    new CognitoUserAttribute(dataEmail),
    new CognitoUserAttribute(dataNit),
    new CognitoUserAttribute(dataNumber),
  ];

  return new Promise((resolve, reject) => {
    userPool.signUp(email.replace("@", "."), password, attributeList, null, (error, result) => {
      if (error) {
        if (error.code === "UsernameExistsException") {
          reject(new Error("El usuario ya existe"));
        } else if (error.code === "UserLambdaValidationException") {
          reject(
              new Error(error.message.replace("PreSignUp failed with error ", ""))
          );
        } else {
          reject(new Error("Error interno, intente nuevamente"));
        }
      } else {
        resolve(result);
      }
    });
  });
}

function ConfirmUser({ user, code }) {
  const userData = {
    Username: user,
    Pool: userPool,
  };

  var cognitoUser = new CognitoUser(userData);
  return new Promise((resolve, reject) => {
    cognitoUser.confirmRegistration(code, true, (error, result) => {
      if (error) {
        reject(error);
      } else {
        resolve(result);
      }
    });
  });
}

function Login(Username, Password) {
  const authDetails = new AuthenticationDetails({ Username, Password });
  const cognitoUser = new CognitoUser({ Username, Pool: userPool });
  return new Promise((resolve, reject) => {
    cognitoUser.authenticateUser(authDetails, {
      onSuccess: (result) => {
        resolve({
          token: result.getIdToken(),
          message: "Login succesfuly",
          accessToken: result.getAccessToken().getJwtToken(),
          refreshToken: result.getRefreshToken().getToken(),
        });
      },
      onFailure: (error) => {
        reject(error);
      },
      newPasswordRequired: (userAttributes) => {
        resolve({ userAttributes, cognitoUser, newPassword: true });
      },
    });
  });
}

function ResendCode(user) {
  const userData = {
    Username: user,
    Pool: userPool,
  };
  var cognitoUser = new CognitoUser(userData);
  cognitoUser.resendConfirmationCode(() => {});
}

function Logout() {}

function ChangePassword(newPassword, userAttributes, cognitoUser) {
  delete userAttributes.email_verified;
  delete userAttributes["custom:usu_id"];
  return new Promise((resolve, reject) =>
    cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, {
      onSuccess: (result) => {
        resolve({
          token: result.getIdToken(),
          message: "Login succesfuly",
          accessToken: result.getAccessToken().getJwtToken(),
          refreshToken: result.getRefreshToken().getToken(),
        });
      },
      onFailure: (error) => {
        reject(error);
      },
    })
  );
}

function RequestChangePassword(user) {
  const userData = {
    Username: user,
    Pool: userPool,
  };
  const cognitoUser = new CognitoUser(userData);
  return new Promise((resolve, reject) => {
    cognitoUser.forgotPassword({
      onSuccess: function (data) {
        resolve(data);
      },
      onFailure: function (error) {
        reject(error);
      },
    });
  });
}

function ConfirmChangePassword(user, password, code) {
  const userData = {
    Username: user,
    Pool: userPool,
  };
  const cognitoUser = new CognitoUser(userData);
  return new Promise((resolve, reject) => {
    cognitoUser.confirmPassword(code, password, {
      onSuccess() {
        resolve({ message: "Cuenta recuperada" });
      },
      onFailure(error) {
        reject(error);
      },
    });
  });
}

function CheckSession() {
  return new Promise((resolve, reject) => {
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser !== null) {
      cognitoUser.getSession(function (err, session) {
        if (err) {
          reject(err);
          return;
        }
        const Logins = {};
        Logins[identityConfig.CognitoIdp] = session.getIdToken().getJwtToken();
        config.credentials = new CognitoIdentityCredentials({
          IdentityPoolId: identityConfig.IdentityPoolId,
          Logins,
        });
        if (AWS.config.credentials.needsRefresh()) {
          cognitoUser.refreshSession(
            session.getRefreshToken(),
            (err, session) => {
              if (err) {
                reject(err);
                return false;
              } else {
                resolve({
                  accessToken: session.accessToken.jwtToken,
                  idToken: session.idToken.jwtToken,
                  refreshToken: session.refreshToken.token,
                  payload: session.idToken.payload,
                });
              }
            }
          );
        } else {
          resolve({
            accessToken: session.accessToken.jwtToken,
            idToken: session.idToken.jwtToken,
            refreshToken: session.refreshToken.token,
            payload: session.idToken.payload,
          });
        }
      });
    }
  });
}

function GetToken() {
  const user = userPool.getCurrentUser();
  const key = `CognitoIdentityServiceProvider.${identityConfig.ClientId}`;
  const userName = user.storage[`${key}.LastAuthUser`];
  return user.storage[`${key}.${userName}.idToken`];
}

function GetUser() {
  return userPool.getCurrentUser()?.username;
}

function SetTokens(params) {
  const key = `CognitoIdentityServiceProvider.${identityConfig.ClientId}`;
  const keyToken = `${key}.${params.user}`;
  localStorage.setItem(`${key}.LastAuthUser`, params.user);
  localStorage.setItem(`${keyToken}.accessToken`, params.accessToken);
  localStorage.setItem(`${keyToken}.refreshToken`, params.refreshToken);
  localStorage.setItem(`${keyToken}.idToken`, params.idToken);
  localStorage.setItem(`${keyToken}.clockDrift`, 0);
}

async function uploadFile(filename, props, outStream, bucket) {
  const s3 = new S3({
    apiVersion: "2006-03-01",
    params: { Bucket: bucket },
  });
  //const user = userPool.getCurrentUser().getUsername();
  const params = {
    Bucket: bucket,
    Key: `${props}/${filename}`,
    Body: outStream,
    ContentType: "application/pdf",
    ACL: "private",
  };
  try {
    await s3.upload(params).promise();
    return "Proceso iniciado con éxito, recibirá un correo cuando el proceso haya finalizado";
  } catch (error) {
    console.log("-temp error");
    console.log(error);
    throw "Permiso denegado, intente nuevamente";
  }
}

export {
  Login,
  Logout,
  ChangePassword,
  SignUp,
  ConfirmUser,
  ResendCode,
  RequestChangePassword,
  ConfirmChangePassword,
  CheckSession,
  GetToken,
  GetUser,
  SetTokens,
  uploadFile,
};
