export function milisecondsToDate(miliseconds, format = "es", utc = true) {
  if (!miliseconds) return "";
  const date = new Date(miliseconds);
  if (format == "es") {
    let day = utc ? date.getUTCDate() : date.getDate();
    let month = utc ? date.getUTCMonth() + 1 : date.getMonth() + 1;
    let year = utc ? date.getUTCFullYear() : date.getUTCFullYear();
    if (day < 10) {
      day = `0${day}`;
    }
    if (month < 10) {
      month = `0${month}`;
    }
    return `${day}-${month}-${year}`;
  }
}

export function secondsTodate(seconds, utc = true, format = "es") {
  if (!seconds) return "";
  const date = new Date(seconds * 1000);
  if (format == "es") {
    let day = utc ? date.getUTCDate() : date.getDate();
    let month = utc ? date.getUTCMonth() + 1 : date.getMonth() + 1;
    let year = utc ? date.getFullYear() : date.getFullYear();
    if (day < 10) {
      day = `0${day}`;
    }
    if (month < 10) {
      month = `0${month}`;
    }
    return `${day}-${month}-${year}`;
  }
}

export function now() {
  return new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
    .toISOString()
    .substr(0, 10);
}

export function milisecondsToInput(miliseconds, format = "es") {
  if (!miliseconds) return "";
  const date = new Date(miliseconds);
  if (format == "es") {
    return `${date.getFullYear()}-${(date.getUTCMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date.getUTCDate().toString().padStart(2, "0")}`;
  }
}

export function toUTCDate(date) {
  var newDate = new Date();
  newDate.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
  return newDate.getTime();
}

export function dateToUnix(date) {
  return new Date(date.replaceAll("-", "/")).getTime();
}

export function firstDayOfMonth() {
  const date = new Date();
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);

  let year = firstDay.getFullYear(),
    month = firstDay.getMonth() + 1,
    day = firstDay.getDate();

  return `${year}-${month < 10 ? "0" + month : month}-${
    day < 10 ? "0" + day : day
  }`;
}

export function lastDayofMonth() {
  const date = new Date();
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  let year = lastDay.getFullYear(),
    month = lastDay.getMonth() + 1,
    day = lastDay.getDate();

  return `${year}-${month < 10 ? "0" + month : month}-${
    day < 10 ? "0" + day : day
  }`;
}
