import Vue from "vue";
import VueApollo from "vue-apollo";
import store from "./store";
import { setContext } from "apollo-link-context";
import { ApolloClient } from "apollo-client";
import { HttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";

const authLink = setContext(async (_, { headers }) => {
  const token = store.state.config.jwt;
  const estId = store.state.config.establishment;
  const estData = estId && store.getters["config/estData"];
  return {
    headers: {
      ...headers,
      Authorization: token || "",
      establishmentdata: estData || "",
    },
  };
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};

const httpLink = new HttpLink({
  uri: process.env.VUE_APP_GRAPQHL,
});

const planillaHttpLink = new HttpLink({
  uri: process.env.VUE_APP_PLANILLA_GRAPHQL,
});

const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  connectToDevTools: true,
  defaultOptions: defaultOptions,
});

const planillaClient = new ApolloClient({
  link: authLink.concat(planillaHttpLink),
  cache: new InMemoryCache(),
  connectToDevTools: true,
  defaultOptions: defaultOptions,
});

Vue.use(VueApollo);

export const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
  defaultOptions: {
    $loadingKey: "loading",
  },
  clients: {
    produccion: apolloClient,
    planilla: planillaClient,
  },
});

export async function onLogout() {
  try {
    await apolloClient.resetStore();
  } catch (e) {
    console.log("%cError on cache reset (logout)", "color: orange;", e.message);
  }
}
