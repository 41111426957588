import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import { GetToken, SetTokens } from "@/core/auth";
import { checkToken } from "@/core/helpers";
// import { onLogout } from "../vue-apollo";
import store from "../store/";

Vue.use(VueRouter);

async function requireAuth(to, from, next) {
  try {
    const token = GetToken();
    if (checkToken(token)) next();
    else throw "Token invalido";
  } catch (error) {
    localStorage.clear();
    const location = window.location;
    const url = location.origin.includes("localhost")
      ? process.env.VUE_APP_LOGIN_URL
      : location.origin.replace("planilla", "login");
    window.location.href = `${url}`;
  }
}

async function checkSession(to, from, next) {
  try {
    const token = GetToken();
    if (checkToken(token)) router.push({ name: "Home" });
    else throw "Token invalido";
  } catch (error) {
    localStorage.clear();
    next();
  }
}

async function setTokens(to) {
  SetTokens(to.params);
  const jsonPayload = window.atob(to.params.estData);
  store.dispatch("config/setEst", JSON.parse(jsonPayload), to.params.idToken);
  router.push({ name: "Home" });
}

const layout =
  process.env.VUE_APP_APP === "disagro" ||
  window.location.host.includes("contamagica")
    ? "DisagroLayout"
    : "PrincipalLayout";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    beforeEnter: requireAuth,
    meta: {
      layout: layout,
    },
  },
  /*{
    path: "/ordenesTrabajo",
    name: "OrdenesTrabajo",
    component: () => import("../views/OrdenesTrabajo.vue"),
    beforeEnter: requireAuth,
  },*/
  {
    path: "/ordenes-trabajo/",
    component: () => import("../views/OrdenesTrabajo.vue"),
    children: [
      {
        path: "",
        name: "OrdenesTrabajo",
        component: () =>
          import("@/components/OrdenesTrabajo/OrdenesTrabajoList.vue"),
        meta: {
          layout: layout,
        },
      },
      {
        path: "detalle/:id",
        name: "OtDetail",
        component: () => import("@/components/OrdenesTrabajo/OrdenDetail.vue"),
        meta: {
          layout: layout,
        },
      },
      /*{
        path: "nueva",
        name: "NewActivity",
        component: () => import("@/components/Activities/CreateActivitie.vue"),
      },
      {
        path: "editar/:id",
        name: "UpdateActivity",
        component: () => import("@/components/Activities/CreateActivitie.vue"),
      },*/
    ],
    beforeEnter: requireAuth,
  },
  {
    path: "/productos",
    name: "Productos",
    component: () => import("../views/Productos.vue"),
    beforeEnter: requireAuth,
    meta: {
      layout: layout,
    },
  },
  {
    path: "/reportes/",
    component: () => import("../views/Reportes.vue"),
    children: [
      {
        path: "",
        name: "Reports",
        component: () => import("../components/Reports/Reportes.vue"),
        meta: {
          layout: layout,
        },
      },
    ],
    beforeEnter: requireAuth,
  },
  {
    path: "/auth",
    component: () => import("../views/Auth.vue"),
    children: [
      {
        path: "",
        name: "Auth",
        component: () => import("@/components/Auth/Login"),
        meta: {
          layout: "LoginLayout",
        },
      },
      {
        path: "change",
        name: "ChangePassword",
        component: () => import("@/components/Auth/ChangePassword"),
        meta: {
          layout: "LoginLayout",
        },
        props: (route) => ({
          ...route.params,
        }),
      },
      {
        path: "recover",
        name: "Recover",
        component: () => import("@/components/Auth/RecoverAccount"),
        meta: {
          layout: "LoginLayout",
        },
      },
    ],
    beforeEnter: checkSession,
  },
  {
    path: "/oauth/:user/:accesToken/:refreshToken/:idToken/:estData",
    name: "OAuth",
    beforeEnter: setTokens,
  },
  {
    path: "/logout",
    name: "Logout",
    beforeEnter() {
      let url = location.origin.includes("localhost")
        ? process.env.VUE_APP_LOGIN_URL
        : location.origin.replace("planilla", "login");
      localStorage.clear();
      window.location.href = `${url}/logout`;
    },
  },
  {
    path: "/configuracion/",
    component: () => import("../views/Configuracion.vue"),
    children: [
      {
        path: "",
        name: "Configuracion",
        component: () =>
          import("../components/Configuracion/Configuracion.vue"),
        meta: {
          layout: layout,
        },
      },
      /*{
        path: "empleados",
        name: "Employees",
        component: () => import("../components/Configuracion/Employees.vue"),
      },*/
      {
        path: "/configuracion/catalogos/",
        component: () => import("../views/Catalogos.vue"),
        children: [
          {
            path: "",
            name: "Catalogos",
            component: () =>
              import("../components/Configuracion/Catalogos.vue"),
            meta: {
              layout: layout,
            },
          },
          {
            path: "tipoProcesos",
            component: () => import("../views/TProcesos.vue"),
            children: [
              {
                path: "",
                name: "TProcesos",
                component: () =>
                  import("@/components/Configuracion/Catalogos/TProcesos.vue"),
                meta: {
                  layout: layout,
                },
              },
              {
                path: "flujo-procesos/orden",
                name: "flujoProcesOrden",
                component: () =>
                  import(
                    "@/components/Configuracion/Catalogos/TProcesos/flujoProcesOrden.vue"
                  ),
                meta: {
                  layout: layout,
                },
              },
              {
                path: "tipo/:id",
                name: "ProdProcess",
                component: () =>
                  import(
                    "@/components/Configuracion/Catalogos/TProcesos/AgregarProducto.vue"
                  ),
                meta: {
                  layout: layout,
                },
              },
            ],
          },
          {
            path: "productosMP",
            name: "ProductsMP",
            component: () =>
              import("../components/Configuracion/Catalogos/ProductsMP.vue"),
            meta: {
              layout: layout,
            },
          },
          {
            path: "productosEntrada",
            name: "ProductosEntrada",
            component: () =>
              import(
                "../components/Configuracion/Catalogos/ProductsEntrada.vue"
              ),
            meta: {
              layout: layout,
            },
          },
          {
            path: "materialEmpaque",
            name: "MaterialEmpaque",
            component: () =>
              import(
                "../components/Configuracion/Catalogos/MaterialEmpaque.vue"
              ),
            meta: {
              layout: layout,
            },
          },
          {
            path: "maquinaria",
            name: "Maquinaria",
            component: () =>
              import("../components/Configuracion/Catalogos/Maquinaria.vue"),
            meta: {
              layout: layout,
            },
          },
          {
            path: "productosSalida",
            name: "ProductosSalida",
            component: () =>
              import(
                "../components/Configuracion/Catalogos/ProductosSalida.vue"
              ),
            meta: {
              layout: layout,
            },
          },
          {
            path: "subproductosSalida",
            name: "SubproductosSalida",
            component: () =>
              import(
                "../components/Configuracion/Catalogos/SubproductosSalida.vue"
              ),
            meta: {
              layout: layout,
            },
          },
        ],
      },
    ],
    beforeEnter: requireAuth,
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
