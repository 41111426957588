import {
  getProductos,
  createProcesoMaquinas,
  getProcesosMaquinas,
  deleteProducto,
  createOrdenProduccionMaquinas,
  getOrdenesProduccionMaquinas,
  updateOrdenProduccionMaquinas,
  deleteOrdenProduccion,
} from "../../api/maquinaria";
import { apolloProvider } from "../../vue-apollo";
const apolloClient = apolloProvider.defaultClient;

const state = () => ({
  maquinarias: [],
  maquinariasPro: [],
  maquinariasProduccion: [],
  maquinaria: null,
  loadingSave: false,
  loading: false,
});

// getters
const getters = {};

// actions
const actions = {
  findProductosMaquinaria({ commit }) {
    commit("setLoading", true);
    apolloClient
      .query({ query: getProductos })
      .then(({ data }) => {
        commit("setProductoMaquinaria", data.getProductos.slice().reverse());
      })
      .catch((error) => {
        console.error(error);
        commit("setLoading", false);
      });
  },
  getProcesosMaquinas({ commit }) {
    const data = {
      prprcId: +localStorage.getItem("prprcId"),
    };
    commit("setLoading", true);
    apolloClient
      .query({ query: getProcesosMaquinas, variables: data })
      .then(({ data }) => {
        commit(
          "setProductoMaquinariaPro",
          data.getProcesosMaquinas.slice().reverse()
        );
      })
      .catch((error) => {
        console.error(error);
        commit("setLoading", false);
      });
  },
  getOrdenProduccionMaquinas({ commit }) {
    const data = {
      proprId: +localStorage.getItem("proprId"),
    };
    commit("setLoading", true);
    apolloClient
      .query({ query: getOrdenesProduccionMaquinas, variables: data })
      .then(({ data }) => {
        commit(
          "setProductoMaquinariaProduccion",
          data.getOrdenesProduccionMaquinas.slice().reverse()
        );
      })
      .catch((error) => {
        console.error(error);
        commit("setLoading", false);
      });
  },
  createProcesoMaquinas({ commit }, { data }) {
    commit("setLoadingSave", true);
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: createProcesoMaquinas,
          variables: { ...data },
        })
        .then((productoEntrada) => {
          commit("createProcesoMaquinas", {
            ...data,
            propeId: productoEntrada.data.createProcesoMaquinas,
          });
          resolve(true);
        })
        .catch((error) => {
          reject(error);
          commit("setLoadingSave", false);
        });
    });
  },
  createOrdenProduccionMaquinas({ commit }, { data }) {
    commit("setLoadingSave", true);
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: createOrdenProduccionMaquinas,
          variables: { ...data },
        })
        .then((productoEntrada) => {
          commit("createOrdenProduccionMaquinas", {
            ...data,
            propaId: productoEntrada.data.createOrdenProduccionMaquinas,
          });
          resolve(true);
        })
        .catch((error) => {
          reject(error);
          commit("setLoadingSave", false);
        });
    });
  },
  updateOrdenProduccionMaquinas({ commit }, { data }) {
    commit("setLoadingSave", true);
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: updateOrdenProduccionMaquinas,
          variables: { ...data },
        })
        .then(() => {
          commit("updateOrdenProduccionMaquinas", data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
          commit("setLoadingSave", false);
        });
    });
  },
  deleteMaquinariaProc({ commit }, { data }) {
    commit("setLoadingSave", true);
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({ mutation: deleteProducto, variables: { ...data } })
        .then(() => {
          commit("deleteMaquinariaProc", data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
          commit("setLoadingSave", false);
        });
    });
  },
  deleteOrdenProduccion({ commit }, { data }) {
    commit("setLoadingSave", true);
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({ mutation: deleteOrdenProduccion, variables: { ...data } })
        .then(() => {
          commit("deleteOrdenProduccion", data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
          commit("setLoadingSave", false);
        });
    });
  },
};

// mutations
const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setLoadingSave(state, loading) {
    state.loadingSave = loading;
  },
  setProductoMaquinaria(state, maquinarias) {
    state.loading = false;
    state.maquinarias = maquinarias;
  },
  setProductoMaquinariaPro(state, maquinarias) {
    state.loading = false;
    state.maquinariasPro = maquinarias;
  },
  setProductoMaquinariaProduccion(state, maquinarias) {
    state.loading = false;
    state.maquinariasProduccion = maquinarias;
  },
  createProcesoMaquinas(state, maquinaria) {
    state.loadingSave = false;
    state.maquinariasPro = [
      {
        ...maquinaria,
        sinv_pro_descripcion: localStorage.getItem("sinv_pro_descripcion_temp"),
        sadm_eliminado: false,
        sadm_fecha_creado: new Date().getTime(),
      },
      ...state.maquinariasPro,
    ];
  },
  createOrdenProduccionMaquinas(state, maquinaria) {
    state.loadingSave = false;
    state.maquinariasProduccion = [
      {
        ...maquinaria,
        sinv_pro_descripcion: localStorage.getItem("sinv_pro_descripcion_temp"),
        sadm_eliminado: false,
        sadm_fecha_creado: new Date().getTime(),
      },
      ...state.maquinariasProduccion,
    ];
  },
  updateOrdenProduccionMaquinas(state, maquinaria) {
    const index = state.maquinariasProduccion.findIndex(
      (item) => item.propaId == maquinaria.propaId
    );
    const items = [...state.maquinariasProduccion];
    items[index] = {
      ...items[index],
      ...maquinaria,
      sadm_fecha_modificado: new Date().getTime(),
    };
    state.loadingSave = false;
    state.maquinariasProduccion = items;
  },
  deleteMaquinariaProc(state, maquinaria) {
    const index = state.maquinarias.findIndex(
      (item) => item.prpmaId === maquinaria.prpmaId
    );
    const items = [...state.maquinarias];
    items[index] = {
      ...items[index],
      sadm_fecha_modificado: new Date().getTime(),
      sadm_eliminado: maquinaria.estado,
    };
    state.loadingSave = false;
    state.maquinarias = items;
  },
  deleteOrdenProduccion(state, maquinaria) {
    const index = state.maquinariasProduccion.findIndex(
      (item) => item.propaId == maquinaria.propaId
    );
    const items = [...state.maquinariasProduccion];
    items[index] = {
      ...items[index],
      sadm_fecha_modificado: new Date().getTime(),
      sadm_eliminado: maquinaria.estado,
    };
    state.loadingSave = false;
    state.maquinariasProduccion = items;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
