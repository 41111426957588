import {
  getProductos,
  createProcesoProductosSalida,
  getProcesosProductosSalida,
  deleteProducto,
  getOrdenesProduccionSalida,
  createOrdenProduccionSalida,
  updateOrdenProduccionSalida,
  deleteOrdenProduccion,
} from "../../api/productosSalida";
import { apolloProvider } from "../../vue-apollo";
const apolloClient = apolloProvider.defaultClient;

const state = () => ({
  productosSalida: [],
  productosSalidaPro: [],
  productosSalidaProduccion: [],
  productoSalida: null,
  loadingSave: false,
  loading: false,
});

// getters
const getters = {};

// actions
const actions = {
  findProductosProductoSalida({ commit }) {
    commit("setLoading", true);
    apolloClient
      .query({ query: getProductos })
      .then(({ data }) => {
        commit("setProductoSalida", data.getProductos.slice().reverse());
      })
      .catch((error) => {
        console.error(error);
        commit("setLoading", false);
      });
  },
  getProcesosProductosSalida({ commit }) {
    const data = {
      prprcId: +localStorage.getItem("prprcId"),
    };
    commit("setLoading", true);
    apolloClient
      .query({ query: getProcesosProductosSalida, variables: data })
      .then(({ data }) => {
        commit(
          "setProductoSalidaPro",
          data.getProcesosProductosSalida.slice().reverse()
        );
      })
      .catch((error) => {
        console.error(error);
        commit("setLoading", false);
      });
  },
  getOrdenesProduccionSalida({ commit }) {
    const data = {
      proprId: +localStorage.getItem("proprId"),
    };
    commit("setLoading", true);
    apolloClient
      .query({ query: getOrdenesProduccionSalida, variables: data })
      .then(({ data }) => {
        commit(
          "setProductoSalidaProduccion",
          data.getOrdenesProduccionSalida.slice().reverse()
        );
      })
      .catch((error) => {
        console.error(error);
        commit("setLoading", false);
      });
  },
  createProcesoProductosSalida({ commit }, { data }) {
    commit("setLoadingSave", true);
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: createProcesoProductosSalida,
          variables: { ...data },
        })
        .then((productoEntrada) => {
          commit("createProcesoProductosSalida", {
            ...data,
            prppsId: productoEntrada.data.createProcesoProductosSalida,
          });
          resolve(true);
        })
        .catch((error) => {
          reject(error);
          commit("setLoadingSave", false);
        });
    });
  },
  createOrdenProduccionSalida({ commit }, { data }) {
    commit("setLoadingSave", true);
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: createOrdenProduccionSalida,
          variables: { ...data },
        })
        .then((productoEntrada) => {
          commit("createOrdenProduccionSalida", {
            ...data,
            propsId: productoEntrada.data.createOrdenProduccionSalida,
          });
          resolve(true);
        })
        .catch((error) => {
          reject(error);
          commit("setLoadingSave", false);
        });
    });
  },
  deleteProductoSalida({ commit }, { data }) {
    commit("setLoadingSave", true);
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({ mutation: deleteProducto, variables: { ...data } })
        .then(() => {
          commit("deleteProductoSalida", data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
          commit("setLoadingSave", false);
        });
    });
  },
  updateOrdenProduccionSalida({ commit }, { data }) {
    commit("setLoadingSave", true);
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: updateOrdenProduccionSalida,
          variables: { ...data },
        })
        .then(() => {
          commit("updateOrdenProduccionSalida", data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
          commit("setLoadingSave", false);
        });
    });
  },
  deleteOrdenProduccion({ commit }, { data }) {
    commit("setLoadingSave", true);
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({ mutation: deleteOrdenProduccion, variables: { ...data } })
        .then(() => {
          commit("deleteOrdenProduccion", data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
          commit("setLoadingSave", false);
        });
    });
  },
};

// mutations
const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setLoadingSave(state, loading) {
    state.loadingSave = loading;
  },
  setProductoSalida(state, productosSalida) {
    state.loading = false;
    state.productosSalida = productosSalida;
  },
  setProductoSalidaPro(state, productosSalida) {
    state.loading = false;
    state.productosSalidaPro = productosSalida;
  },
  setProductoSalidaProduccion(state, productosSalida) {
    state.loading = false;
    state.productosSalidaProduccion = productosSalida;
  },
  createProcesoProductosSalida(state, productoSalida) {
    state.loadingSave = false;
    state.productosSalidaPro = [
      {
        ...productoSalida,
        sinv_pro_descripcion: localStorage.getItem("sinv_pro_descripcion_temp"),
        sadm_eliminado: false,
        sadm_fecha_creado: new Date().getTime(),
      },
      ...state.productosSalidaPro,
    ];
  },
  createOrdenProduccionSalida(state, productoSalida) {
    state.loadingSave = false;
    state.productosSalidaProduccion = [
      {
        ...productoSalida,
        sinv_pro_descripcion: localStorage.getItem("sinv_pro_descripcion_temp"),
        sadm_eliminado: false,
        sadm_fecha_creado: new Date().getTime(),
      },
      ...state.productosSalidaProduccion,
    ];
  },
  deleteProductoSalida(state, productoSalida) {
    const index = state.productosSalida.findIndex(
      (item) => item.prppsId === productoSalida.prppsId
    );
    const items = [...state.productosSalida];
    items[index] = {
      ...items[index],
      sadm_fecha_modificado: new Date().getTime(),
      sadm_eliminado: productoSalida.estado,
    };
    state.loadingSave = false;
    state.productosSalida = items;
  },
  updateOrdenProduccionSalida(state, productoSalida) {
    const index = state.productosSalidaProduccion.findIndex(
      (item) => item.propsId == productoSalida.propsId
    );
    const items = [...state.productosSalidaProduccion];
    items[index] = {
      ...items[index],
      ...productoSalida,
      sadm_fecha_modificado: new Date().getTime(),
    };
    state.loadingSave = false;
    state.productosSalidaProduccion = items;
  },
  deleteOrdenProduccion(state, productoSalida) {
    const index = state.productosSalidaProduccion.findIndex(
      (item) => item.propsId == productoSalida.propsId
    );
    const items = [...state.productosSalidaProduccion];
    items[index] = {
      ...items[index],
      sadm_fecha_modificado: new Date().getTime(),
      sadm_eliminado: productoSalida.estado,
    };
    state.loadingSave = false;
    state.productosSalidaProduccion = items;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
