import gql from "graphql-tag";

export const getProceso = gql`
  query getProceso($id: ID!) {
    getProceso(id: $id) {
      prprcId
      prprcNombre
      sadm_eliminado
      sadm_idcrea
      sadm_fecha_creado
      sadm_fecha_modificado
    }
  }
`;

export const findProcesos = gql`
  query {
    findProcesos {
      prprcId
      prprcNombre
      prprcSubProceso
      prprcCreaProductoNuevo
      prprcOrdenProceso
      sadm_eliminado
      sadm_idcrea
      sadm_fecha_creado
      sadm_fecha_modificado
    }
  }
`;

export const createProceso = gql`
  mutation createProceso($data: CreateProcesoInput!) {
    createProceso(data: $data)
  }
`;

export const updateProceso = gql`
  mutation updateProceso($prprcId: ID!, $data: UpdateProcesoInput!) {
    updateProceso(id: $prprcId, data: $data)
  }
`;

export const deleteProceso = gql`
  mutation updateProceso($prprcId: ID!, $estado: Boolean) {
    updateProceso(id: $prprcId, data: { sadm_eliminado: $estado })
  }
`;
