import { getAccesos } from "../../api/establishment";
import { objectToBase64 } from "@/core/utils";
import { apolloProvider } from "../../vue-apollo";
const apolloClient = apolloProvider.defaultClient;

const state = () => ({
  toggleMini: true,
  businessUnit: localStorage.getItem("businessUnit"),
  company: localStorage.getItem("company"),
  establishment: localStorage.getItem("establishment"),
  establishmentName: localStorage.getItem("establishmentName"),
  name: localStorage.getItem("name"),
  estIdCentral:
    localStorage.getItem("estIdCentral") === "null"
      ? null
      : localStorage.getItem("estIdCentral"),
  user: "",
  accesos: [],
  empresas: [],
  establecimientos: [],
  loading: true,
  jwt: "",
});

// getters
const getters = {
  estData: (state) =>
    objectToBase64({
      corId: state.businessUnit,
      empId: state.company,
      estId: state.establishment,
      estIdCentral: state.estIdCentral,
    }),
};

// actions
const actions = {
  changeMenu({ commit }, toggleMini) {
    commit("setActiveMenu", toggleMini);
  },
  setBusinessUnit({ commit, state, dispatch }, businessUnit) {
    commit("setBusinessUnit", businessUnit);
    if (businessUnit === null) {
      localStorage.removeItem("businessUnit");
      dispatch("setCompany", null);
      return;
    }
    const { corId, empresas } = state.accesos[businessUnit];
    localStorage.setItem("businessUnit", corId);
    let index = 0;
    if (empresas.length !== 1) {
      index = empresas.findIndex((emp) => emp.empId === state.company);
      index = index === -1 ? null : index;
    }
    dispatch("setCompany", index);
  },
  setCompany({ commit, state, dispatch }, company) {
    commit("setCompany", company);
    if (company === null) {
      localStorage.removeItem("company");
      dispatch("setEstablishment", null);
      return;
    }

    const { empId, establecimientos } = state.empresas[company];
    localStorage.setItem("company", empId);
    let index = 0;
    if (establecimientos.length !== 1) {
      index = establecimientos.findIndex(
        (est) => est.estId === state.establishment
      );
      index = index === -1 ? null : index;
    }
    dispatch("setEstablishment", index);
  },
  setEstablishment({ commit, state }, establishment) {
    establishment !== null
      ? localStorage.setItem(
          "establishment",
          state.establecimientos[establishment].estId
        )
      : localStorage.removeItem("establishment");
    commit("setEstablishment", establishment);
  },
  login({ commit }, jwt) {
    localStorage.setItem("name", jwt.token.payload.name);
    commit("clear");
    commit("setName", jwt.token.payload.name);
    commit("setUser", jwt.token.payload["custom:usu_id"]);
    commit(
      "alerts/setMessage",
      { message: "Bienvenido!", type: "success" },
      { root: true }
    );
    commit("setUserJwt", jwt.token.jwtToken);
  },
  getAccesos({ commit, dispatch, state }, { id }) {
    commit("setLoading", true);
    apolloClient
      .query({ query: getAccesos, variables: { id } })
      .then(({ data }) => {
        commit("setAccesos", data.getAccesosEstablecimientos);
        if (data.getAccesosEstablecimientos.length === 1) {
          dispatch("setBusinessUnit", 0);
        } else if (state.businessUnit) {
          const index = data.getAccesosEstablecimientos.findIndex(
            (corp) => corp.corId === state.businessUnit
          );
          dispatch("setBusinessUnit", index === -1 ? null : index);
        }
      })
      .catch((error) => {
        console.error(error);
        commit("setLoading", false);
      });
  },
  setUser({ commit }, user) {
    commit("setUser", user);
  },
  setUserJwt({ commit }, { jwt, info }) {
    commit("setLoading", true);
    commit("setUserJwt", jwt);
    commit("setName", info.name);
    commit("setUser", info["custom:usu_id"]);
    commit("setLoading", false);
  },
  setEst({ commit }, data) {
    localStorage.setItem("establishment", data.estId);
    localStorage.setItem("establishmentName", data.estSiglas);
    localStorage.setItem("company", data.empId);
    localStorage.setItem("businessUnit", data.corId);
    localStorage.setItem("name", data.usuNombre);
    localStorage.setItem("estIdCentral", data.sadm_id_central);
    commit("setEst", data);
  },
};

// mutations
const mutations = {
  setActiveMenu(state, toggleMini) {
    state.toggleMini = toggleMini;
  },
  setBusinessUnit(state, index) {
    if (index === null) {
      state.businessUnit = index;
      state.empresas = [];
    } else {
      state.businessUnit = state.accesos[index].corId;
      state.empresas = state.accesos[index].empresas;
    }
  },
  setCompany(state, index) {
    if (index === null) {
      state.company = index;
      state.establecimientos = [];
    } else {
      state.company = state.empresas[index].empId;
      state.establecimientos = state.empresas[index].establecimientos;
    }
  },
  setEstablishment(state, index) {
    if (index === null) {
      state.establishment = index;
    } else {
      state.establishment = state.establecimientos[index].estId;
      state.establishmentName = state.establecimientos[index].siglas;
    }
  },
  setName(state, name) {
    state.name = name;
  },
  clear(state) {
    state.businessUnit = undefined;
    state.company = undefined;
    state.establishment = undefined;
    state.accesos = [];
    state.empresas = [];
    state.establecimientos = [];
    state.establishmentName = "";
    state.user = "";
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setAccesos(state, accesos) {
    state.accesos = accesos;
    state.loading = false;
  },
  setUser(state, user) {
    state.user = user;
  },
  setUserJwt(state, jwt) {
    state.jwt = jwt;
  },
  setEst(state, est) {
    state.businessUnit = est.corId + "";
    state.company = est.empId + "";
    state.establishment = est.estId + "";
    state.name = est.usuNombre;
    state.user = est.usuId;
    state.establishmentName = est.estSiglas;
    state.estIdCentral = est.sadm_id_central;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
