import { getProductos } from "../../api/subproductosSalida";
import { apolloProvider } from "../../vue-apollo";
const apolloClient = apolloProvider.defaultClient;

const state = () => ({
  subproductosSalida: [],
  subproductoSalida: null,
  loadingSave: false,
  loading: false,
});

// getters
const getters = {};

// actions
const actions = {
  findSubproductosSalida({ commit }) {
    commit("setLoading", true);
    apolloClient
      .query({ query: getProductos })
      .then(({ data }) => {
        commit("setSubproductoSalida", data.getProductos.slice().reverse());
      })
      .catch((error) => {
        console.error(error);
        commit("setLoading", false);
      });
  },
};

// mutations
const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setLoadingSave(state, loading) {
    state.loadingSave = loading;
  },
  setSubproductoSalida(state, subproductosSalida) {
    state.loading = false;
    state.subproductosSalida = subproductosSalida;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
