<template>
  <v-card flat color="white">
    <v-card-text>
      <v-row class="px-0">
        <v-col
          cols="12"
          sm="6"
          :lg="currentSystem === 'disagro' ? 12 : 6"
          align="right"
          class="d-sm-flex align-sm-center"
          :class="
            currentSystem === 'disagro' ? 'justify-sm-center' : 'justify-sm-end'
          "
        >
          <h1
            class="interlineado"
            :class="currentSystem === 'disagro' ? 'pa-5' : 'pa-10'"
          >
            ¡ Nos alegra que estes de vuelta
            <span class="primary--text">{{ firstName }}</span> !
          </h1>
        </v-col>
        <v-col
          v-if="currentSystem !== 'disagro'"
          cols="12"
          sm="6"
          align="right"
          class="d-sm-flex pb-0 justify-sm-end align-sm-end px-0"
        >
          <img src="/img/icon.png" alt="alt" width="200" class="" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "myWelcome",
  data: function () {
    return {
      currentSystem:
        process.env.VUE_APP_APP ||
        (window.location.host.includes("contamagica") ? "disagro" : "mynube"),
    };
  },
  computed: {
    ...mapState({
      name: (state) => state.config.name,
    }),
    firstName() {
      return this.name.split(" ")[0];
    },
  },
};
</script>

<style scoped>
.interlineado {
  line-height: 1.2em;
}
</style>
<style scoped src="@/assets/css/globals.css"></style>
