import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

const disagro = {
  theme: {
    dark: false,
    themes: {
      dark: {
        primary: "#179b44",
        secondary: "#b0bec5",
        accent: "#8c9eff",
        error: "#b71c1c",
        navbar: colors.grey.darken4,
        titleColor: "#fff",
        headerColor: "#179b44",
      },
      light: {
        primary: "#009845",
        primary_soft: "#b3e0c7",
        headerColor: "#009845",
        secondary: "#113032",
        support: colors.grey.darken2,
        mpla_bg: "#f0f1f6",
        pla_c3: "#F6D316",
        pla_c3_soft: "#f2f1d8",
        grey_saf: "#4d4d4d",
      },
    },
  },
};

const mynube = {
  theme: {
    dark: false,
    themes: {
      dark: {
        primary: "#179b44",
        secondary: "#b0bec5",
        accent: "#8c9eff",
        error: "#b71c1c",
        navbar: colors.grey.darken4,
        titleColor: "#fff",
        headerColor: "#179b44",
      },
      light: {
        /*primary: "#179b44",
      primary_soft: "#d8f2e0",
      headerColor: "#179b44",*/
        // v2
        /*primary: "#347c00",
      primary_soft: "#c2d8b3",
      headerColor: "#347c00",
      secondary: "#123032",*/
        // v3
        primary: "#009845",
        primary_soft: "#b3e0c7",
        headerColor: "#009845",
        secondary: "#113032",
        support: colors.grey.darken2,
        mpla_bg: "#f0f1f6",
        pla_c3: "#F6D316",
        pla_c3_soft: "#f2f1d8",
      },
    },
  },
};

export default new Vuetify(
  process.env.VUE_APP_APP === "disagro" ||
  window.location.host.includes("contamagica")
    ? disagro
    : mynube
);
