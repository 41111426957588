import gql from "graphql-tag";

export const getProductos = gql`
  query {
    getProductos(query: { tipoProductoPlanilla: SUBPRODUCTO_SALIDA }) {
      sinv_pro_id
      sinv_pro_descripcion
      sinv_pro_codigo
      sinv_pro_codigo_alterno
      sinv_pro_descripcion
      sinv_ume_siglassalida
    }
  }
`;
