import gql from "graphql-tag";

export const getProductos = gql`
  query {
    getProductos(query: { tipoProductoPlanilla: INSUMO }) {
      sinv_pro_id
      sinv_pro_codigo
      sinv_pro_codigo_alterno
      sinv_pro_descripcion
      sinv_ume_siglassalida
    }
  }
`;

export const createProcesoInsumos = gql`
  mutation createProcesoInsumos($sinv_pro_id: Int!, $prprcId: Int!) {
    createProcesoInsumos(data: { sinv_pro_id: $sinv_pro_id, prprcId: $prprcId })
  }
`;

export const getOrdenesProduccionInsumos = gql`
  query getOrdenesProduccionInsumos($proprId: Int) {
    getOrdenesProduccionInsumos(query: { proprId: $proprId }) {
      propiId
      sinv_pro_id
      proprId
      sinv_pro_descripcion
      propiPeso
      propiCantidad
      sadm_cor_id
      sadm_emp_id
      sadm_est_id
      sadm_eliminado
      sadm_idcrea
      sadm_fecha_creado
      sadm_fecha_modificado
    }
  }
`;

export const getProcesosInsumos = gql`
  query getProcesosInsumos($prprcId: Int) {
    getProcesosInsumos(query: { prprcId: $prprcId }) {
      prpinId
      sinv_pro_descripcion
      sinv_pro_id
      prprcId
      sadm_cor_id
      sadm_emp_id
      sadm_est_id
      sadm_eliminado
      sadm_idcrea
      sadm_fecha_creado
      sadm_fecha_modificado
    }
  }
`;

export const createOrdenProduccionInsumos = gql`
  mutation createOrdenProduccionInsumos(
    $sinv_pro_id: Int!
    $proprId: Int!
    $propiPeso: Float!
    $propiCantidad: Float!
  ) {
    createOrdenProduccionInsumos(
      data: {
        sinv_pro_id: $sinv_pro_id
        proprId: $proprId
        propiPeso: $propiPeso
        propiCantidad: $propiCantidad
      }
    )
  }
`;

export const updateOrdenProduccionInsumos = gql`
  mutation updateOrdenProduccionInsumos(
    $propiId: ID!
    $sinv_pro_id: Int
    $propiPeso: Float
    $propiCantidad: Float
    $proprId: Int
  ) {
    updateOrdenProduccionInsumos(
      id: $propiId
      data: {
        sinv_pro_id: $sinv_pro_id
        propiPeso: $propiPeso
        propiCantidad: $propiCantidad
        proprId: $proprId
        sadm_eliminado: false
      }
    )
  }
`;

export const deleteOrdenProduccion = gql`
  mutation updateOrdenProduccionInsumos($propiId: ID!, $estado: Boolean) {
    updateOrdenProduccionInsumos(
      id: $propiId
      data: { sadm_eliminado: $estado }
    )
  }
`;

export const deleteProducto = gql`
  mutation updateProcesoInsumos($prpinId: ID!, $estado: Boolean) {
    updateProcesoInsumos(id: $prpinId, data: { sadm_eliminado: $estado })
  }
`;
