<template>
  <v-card class="mx-auto rounded" flat color="secondary">
    <v-row no-gutters class="">
      <v-col cols="12" md="6" class="">
        <v-img
          :src="'/img/instalar.jpg'"
          class="white--text align-end middle-rounded"
          gradient="to bottom, rgba(0,139,0,.30), rgba(14,80,14,.66)"
        >
        </v-img>
      </v-col>
      <v-col cols="12" md="6" align="center" class="">
        <v-icon size="60" color="primary" class="pt-4 pb-2">
          mdi-cloud-download-outline
        </v-icon>
        <div class="text-left px-5 pb-4">
          <small class="white--text">
            Al momento de instalar la app, se convertirá en una aplicación de
            escritorio o móvil para que puedas usarla sin necesidad de abrir el
            navegador.
          </small>
        </div>
        <v-btn
          small
          color="white"
          outlined
          @click="handleSync"
          :loading="loadingSync"
        >
          instalar App
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "InstallApp",
  props: {
    mini: Boolean,
  },
  data: function () {
    return {
      dialog: false,
      loadingSync: false,
    };
  },
  methods: {
    handleSync() {
      this.loadingSync = true;
      setTimeout(() => {
        this.loadingSync = false;
        this.dialog = false;
      }, 3000);
    },
  },
};
</script>

<style scoped>
.middle-rounded {
  border-radius: 4px 0px 0px 4px !important;
}
</style>
