<template>
  <Layout>
    <router-view />
  </Layout>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { CheckSession } from "@/core/auth";

export default {
  name: "App",
  data: () => ({}),
  computed: {
    ...mapState({
      message: (state) => state.alerts.message,
    }),
  },
  watch: {
    message(newValue) {
      const { message, type } = newValue;
      if (type !== "info") {
        this.$toast.open({
          message: message,
          type: type,
          duration: 5000,
          position: "top-right",
        });
      } else {
        this.$toast.open({
          message: message,
          type: type,
          duration: 5000,
          position: "top",
        });
      }
    },
  },
  methods: {
    async loadEstablishment() {
      try {
        const tokens = await CheckSession();
        this.setUserJwt({ jwt: tokens.idToken, info: tokens.payload });
      } catch (error) {
        this.$router.push({ name: "Auth" });
      }
    },
    ...mapActions({
      getAccesos: "config/getAccesos",
      setUserJwt: "config/setUserJwt",
    }),
  },
  mounted() {
    this.loadEstablishment();
  },
};
</script>

<style>
/*.colForm {
  padding-left: 5px !important;
  padding-right: 5px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}*/
</style>
