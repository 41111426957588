import { apolloProvider } from "../../vue-apollo";
import {
  cerrarOrdenProduccion,
  createOrdenProduccion,
  deleteOrdenProduccion,
  findOrdenesProduccion,
  updateOrdenProduccion,
} from "../../api/ordenesProduccion";
const apolloClient = apolloProvider.defaultClient;

const state = () => ({
  ordenesProduccion: [],
  ordenProduccion: null,
  loadingSave: false,
  loading: false,
});
// getters
const getters = {};
// actions
const actions = {
  /*findOrdenesProduccion({ commit }, { data }) {*/
  /*  findOrdenesProduccion({ commit }) {
    commit("setLoading", true);
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: findOrdenesProduccion,
        })
        .then((response) => {
          commit("setLoading", false);
          /!*resolve(response.data.getActividadesRealizadas);*!/
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
          commit("setLoading", false);
        });
    });
  },*/
  findOrdenesProduccion({ commit, state }, force = false) {
    commit("setLoading", true);
    if (state.ordenesProduccion.length > 0 && !force) {
      commit("setLoading", false);
      return;
    }
    apolloClient
      .query({ query: findOrdenesProduccion })
      .then(({ data }) => {
        commit(
          "setOrdenesProduccion",
          data.findOrdenesProduccion.slice().reverse()
        );
      })
      .catch((error) => {
        console.error(error);
        commit("setLoading", false);
      });
  },
  createOrdenProduccion({ commit }, { data }) {
    commit("setLoadingSave", true);
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: createOrdenProduccion,
          variables: { data },
        })
        .then((response) => {
          let responseObject = response.data.createOrdenProduccion;
          commit("createOrdenProduccion", {
            ...data,
            ...responseObject,
            proprId: +responseObject.insertId,
          });
          resolve(true);
        })
        .catch((error) => {
          reject(error);
          commit("setLoadingSave", false);
        });
    });
  },
  updateOrdenProduccion({ commit }, { data }) {
    commit("setLoadingSave", true);
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({ mutation: updateOrdenProduccion, variables: { ...data } })
        .then(() => {
          commit("updateOrdenProduccion", {
            proprId: data.proprId,
            ...data.data,
          });
          resolve(true);
        })
        .catch((error) => {
          reject(error);
          commit("setLoadingSave", false);
        });
    });
  },
  deleteOrdenProduccion({ commit, reject }, { data }) {
    commit("setLoadingSave", true);
    return new Promise((resolve) => {
      apolloClient
        .mutate({ mutation: deleteOrdenProduccion, variables: { ...data } })
        .then(() => {
          commit("deleteOrdenProduccion", {
            ...data,
          });
          let message = data.estado == true ? "Se anuló" : "Se activó";
          message +=
            " la orden de producción, puedes cambiarla antes de abandonar la pantalla";
          commit(
            "alerts/setMessage",
            {
              message: message,
              type: "success",
            },
            { root: true }
          );
          resolve(true);
        })
        .catch((error) => {
          reject(error);
          commit("setLoadingSave", false);
        });
    });
  },

  cerrarOrdenProduccion({ commit }, { data }) {
    commit("setLoadingSave", true);
    return new Promise((resolve) => {
      apolloClient
        .mutate({
          mutation: cerrarOrdenProduccion,
          variables: { data },
        })
        .then(() => {
          commit("setLoadingSave", false);
          commit(
            "alerts/setMessage",
            {
              message: "Se ha cerrado la orden de producción exitosamente.",
              type: "success",
            },
            { root: true }
          );
          commit("updateOrdenProduccion", {
            proprId: data.proprId,
            proprStatus: "OPE",
          });
          resolve(true);
        })
        .catch((error) => {
          commit(
            "alerts/setMessage",
            { message: error, type: "error" },
            { root: true }
          );
          resolve(true);
          commit("setLoadingSave", false);
        });
    });
  },
};

// mutations
const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setLoadingSave(state, loading) {
    state.loadingSave = loading;
  },
  setOrdenesProduccion(state, ordenesProduccion) {
    state.loading = false;
    state.ordenesProduccion = ordenesProduccion;
  },
  setOrdenProduccion(state, ordenProduccion) {
    state.loading = false;
    state.ordenProduccion = ordenProduccion;
  },
  createOrdenProduccion(state, ordenProduccion) {
    state.loadingSave = false;
    state.ordenesProduccion = [
      {
        ...ordenProduccion,
        sadm_eliminado: false,
        sadm_fecha_creado: new Date().getTime(),
      },
      ...state.ordenesProduccion,
    ];
  },
  updateOrdenProduccion(state, ordenProduccion) {
    ordenProduccion.proprId = ordenProduccion.proprId.toString();
    const index = state.ordenesProduccion.findIndex(
      (item) => item.proprId === ordenProduccion.proprId
    );
    const items = [...state.ordenesProduccion];
    items[index] = {
      ...items[index],
      ...ordenProduccion,
      sadm_fecha_modificado: new Date().getTime(),
    };
    state.loadingSave = false;
    state.ordenesProduccion = items;
  },
  deleteOrdenProduccion(state, ordenProduccion) {
    const index = state.ordenesProduccion.findIndex(
      (item) => item.proprId === ordenProduccion.proprId
    );
    const items = [...state.ordenesProduccion];
    items[index] = {
      ...items[index],
      sadm_fecha_modificado: new Date().getTime(),
      sadm_eliminado: ordenProduccion.estado,
    };
    state.loadingSave = false;
    state.ordenesProduccion = items;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
